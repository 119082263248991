import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCar,
  faCircleInfo,
  faGear,
  faHouse,
  faUser,
  faUsers,
  fas,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../../utils/context";
import { faAdversal } from "@fortawesome/free-brands-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
library.add(fas);

const Sidebar = () => {
  const { isAllow } = useContext(Context);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("1");

  const [activeAccordionItem, setActiveAccordionItem] = useState("1");
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  const renderTooltip = (name) => (props) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {name}
      </Tooltip>
    );

  return (
    <>
      <section style={{ width: isOpen ? "250px" : "50px" }} className="sidebar">
        <div className="top_section">
          {isOpen && (
            <Link
              to="/dashboard"
              className="link-body-emphasis d-inline-flex text-decoration-none rounded"
            >
              <div className="img-holder">
                <img
                  className="logo-img"
                  src={process.env.PUBLIC_URL + "/assets/Image/Logo.png"}
                  alt="logo-img"
                />
              </div>
            </Link>
          )}
          <div style={{ marginLeft: isOpen ? "30px" : "3px" }} className="bars">
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>

        <ul className="list-unstyled ps-0">
          <Accordion defaultActiveKey={activeAccordionItem}>
            {/* dashboard tab start*/}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("Dashboard")}
              // eventKey="1"
            >
              <Accordion.Item
                eventKey="1"
                className={`mb-1 ${
                  activeAccordionItem === "1"
                    ? "accordian-holder active"
                    : "accordian-holder"
                }`}
                onClick={() => handleAccordionClick("1")}
              >
                <Link to="/dashboard" className="text-decoration-none ">
                  <Accordion.Header>
                    <div className="text-clr me-3">
                      <FontAwesomeIcon
                        icon={faHouse}
                        style={{ color: "rgb(94, 114, 228)" }}
                      />
                    </div>
                    <p
                      // onClick={() => setIsOpen(!isOpen)}
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      Dashboard
                    </p>
                  </Accordion.Header>
                </Link>
              </Accordion.Item>
              {/* dashboard tab end*/}
            </OverlayTrigger>

            {/* settings tab start*/}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("settings")}
              eventKey="2"
            >
              <Accordion.Item
                // eventKey="2"
                // className="mb-1"
                // onClick={() => setIsOpen(true)}
                eventKey="2"
                className={`mb-1 ${
                  activeAccordionItem === "2" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("2")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-3">
                    <FontAwesomeIcon
                      icon={faGear}
                      style={{ color: "rgb(251, 99, 64)" }}
                    />
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Settings
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {/* Language */}
                    {/* <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/admin-earnings")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("admin-earnings")}
                    >
                      <Link
                        to="/masters/admin-earnings"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Admin Earnings
                        </p>
                      </Link>
                    </li> */}
                    {/* App Setup */}
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/app-setup")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("app-setup")}
                    >
                      <Link
                        to="/masters/app-setup"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          App Setup
                        </p>
                      </Link>
                    </li>
                    {/* Country */}
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/country")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("country")}
                    >
                      <Link
                        to="/masters/country"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Country
                        </p>
                      </Link>
                    </li>
                    {/* State */}
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/state")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("state")}
                    >
                      <Link
                        to="/masters/state"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          State
                        </p>
                      </Link>
                    </li>
                    {/* City */}
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/city")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("city")}
                    >
                      <Link
                        to="/masters/city"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          City
                        </p>
                      </Link>
                    </li>
                    {/* Pincode */}
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/pincode")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("pincode")}
                    >
                      <Link
                        to="/masters/pincode"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Pincode
                        </p>
                      </Link>
                    </li>{" "}
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/rider-partner")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("rider-partner")}
                    >
                      <Link
                        to="/masters/rider-partner"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          User Feedback 
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/category-vehicle")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("category-vehicle")}
                    >
                      <Link
                        to="/masters/category-vehicle"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Vehicle Category
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/fare-vehicle")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("fare-vehicle")}
                    >
                      <Link
                        to="/masters/fare-vehicle"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Vehicle Fare
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/vehicle")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("vehicle")}
                    >
                      <Link
                        to="/masters/vehicle"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Vehicle
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/assign-vehicle")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("assign-vehicle")}
                    >
                      <Link
                        to="/masters/assign-vehicle"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Assign Vehicle
                        </p>
                      </Link>
                    </li>
                  </ul>
                  {/* </div> */}
                </Accordion.Body>
              </Accordion.Item>
            </OverlayTrigger>
            {/* settings tab end*/}

            {/* Customers  tab start*/}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("Customers")}
              eventKey="3"
            >
              <Accordion.Item
                // eventKey="2"
                // className="mb-1"
                // onClick={() => setIsOpen(true)}
                eventKey="3"
                className={`mb-1 ${
                  activeAccordionItem === "3" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("3")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-3">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: "rgb(45, 206, 137)" }}
                    />
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Riders
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {/* Language */}

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/customers/riders")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("riders")}
                    >
                      <Link
                        to="/customers/riders"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Riders
                        </p>
                      </Link>
                    </li>

                    {/* Country */}

                   
                  </ul>
                  {/* </div> */}
                </Accordion.Body>
              </Accordion.Item>
            </OverlayTrigger>

            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("Customers")}
              eventKey="8"
            >
              <Accordion.Item
                // eventKey="2"
                // className="mb-1"
                // onClick={() => setIsOpen(true)}
                eventKey="8"
                className={`mb-1 ${
                  activeAccordionItem === "8" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("8")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-3">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: "rgb(45, 206, 137)" }}
                    />
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Customer
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {/* Language */}

                 

                    {/* Country */}

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/customers/users")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("country")}
                    >
                      <Link
                        to="/customers/users"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Customer
                        </p>
                      </Link>
                    </li>
                  </ul>
                  {/* </div> */}
                </Accordion.Body>
              </Accordion.Item>
            </OverlayTrigger>
            {/* Customers  tab end*/}

            {/* Rides  tab start*/}

            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("Rides")}
              eventKey="4"
            >
              <Accordion.Item
                eventKey="4"
                className={`mb-1 ${
                  activeAccordionItem === "4" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("4")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-3">
                    <FontAwesomeIcon
                      icon={faCar}
                      style={{ color: "rgb(17, 205, 239)" }}
                    />
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Rides
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/customers/rides")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("rides")}
                    >
                      <Link
                        to="/customers/rides"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Rides
                        </p>
                      </Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </OverlayTrigger>
            {/* Rides  tab end*/}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("Contact Us")}
              eventKey="3"
            >
              <Accordion.Item
                // eventKey="2"
                // className="mb-1"
                // onClick={() => setIsOpen(true)}
                eventKey="7"
                className={`mb-1 ${
                  activeAccordionItem === "7" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("7")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-3">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: "rgb(45, 206, 137)" }}
                    />
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Contact Us
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {/* Language */}

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/customers/contact-us")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("contact-us")}
                    >
                      <Link
                        to="/customers/contact-us"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Contact Us
                        </p>
                      </Link>
                    </li>

                    {/* Country */}

                   
                  </ul>
                  {/* </div> */}
                </Accordion.Body>
              </Accordion.Item>
            </OverlayTrigger>

            {/* Help  tab start*/}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("Help")}
              eventKey="5"
            >
              <Accordion.Item
                eventKey="5"
                className={`mb-1 ${
                  activeAccordionItem === "5" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("5")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-3">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      style={{ color: "rgb(251, 99, 64)" }}
                    />
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Help
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/user-help-type")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("user-help-type")}
                    >
                      <Link
                        to="/masters/user-help-type"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          User Help Type
                        </p>
                      </Link>
                    </li>
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/user-help-sub-type")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("user-help-sub-type")
                      }
                    >
                      <Link
                        to="/masters/user-help-sub-type"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          User Help Sub Type
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/user-help")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("user-help")}
                    >
                      <Link
                        to="/masters/user-help"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          User Help
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/rider-help-type")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("rider-help-type")
                      }
                    >
                      <Link
                        to="/masters/rider-help-type"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Rider Help Type
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/rider-help")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("rider-help")}
                    >
                      <Link
                        to="/masters/rider-help"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Rider Help
                        </p>
                      </Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </OverlayTrigger>
            {/* Help  tab end*/}

            {/* Adverticement  tab start*/}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip("Adverticement")}
              eventKey="6"
            >
              <Accordion.Item
                eventKey="6"
                className={`mb-1 ${
                  activeAccordionItem === "6" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("6")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-3">
                    <FontAwesomeIcon
                      icon={faAdversal}
                      style={{ color: "rgb(52, 71, 103)" }}
                    />
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Advertisement
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/user-advertisement")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("user-advertisement")
                      }
                    >
                      <Link
                        to="/masters/user-advertisement"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          User Advertisement
                        </p>
                      </Link>
                    </li>
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/rider-advertisement")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("rider-advertisement")
                      }
                    >
                      <Link
                        to="/masters/rider-advertisement"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Rider Advertisement
                        </p>
                      </Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </OverlayTrigger>
            {/* Adverticement  tab end*/}

            {/* Drivers  tab start*/}
            
            {/* Drivers  tab end*/}
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
